import styled from 'styled-components';

export const Wrapper = styled.div`
    .btn {
        cursor: pointer;
    }
`;

export const SHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    background-color:#d3e8d8;
    font-size:var(--fontSMed);
    font-weight:600;
    border-top: 3px solid var(--lightGreen);
`;

export const SalesGoods = styled.div`
    /* @media screen and (max-width: 768px){
        padding-bottom: 50px;
    } */
`;

export const GoodsInfo = styled.div`

`;

export const StockInfo = styled.div`

`;

export const GoodsStock = styled.div`
    display:flex;
    justify-content: center;
    margin: 5px auto;
    text-align: center;
    
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px 10px;
    }
   
    th{
        background-color: #f0f0f0;
    }
    .align-left{
        text-align: left;
    }
`;