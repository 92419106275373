import React from 'react';
import { useGoodsDetails } from './useGoodsDetails';
import SalesSlipsOfGoods from './components/SalesSlipsOfGoods';
import Summary from './components/Summary';

import { Wrapper, Content } from './styles';
import Loading from '../../components/Loading';
import HeaderBox from '../../components/HeaderBox';
import MessageBox from '../../components/MessageBox';
import BackButton from '../../components/BackButton';

const GoodsDetails = () => {
    const { goodsDetails, isLoading, message, setMessage } = useGoodsDetails();

    return (<>
        {goodsDetails?.goodsID && (
            <Wrapper>
                <Loading isLoading={isLoading} />
                <HeaderBox title={`${goodsDetails?.goodsInfo?.GoodsName || ""}｜${goodsDetails?.goodsInfo?.Size || ""}`} showCart={true} />
                <MessageBox message={message} setMessage={setMessage} />
                <Content>
                    <Summary goodsStock={goodsDetails?.goodsStock || []}
                        goods={goodsDetails?.goodsInfo}
                    />
                    <SalesSlipsOfGoods salesGoods={goodsDetails?.salesGoods || []}
                        twitterGoods={goodsDetails?.twitterGoods || []}
                        stockInfoList={goodsDetails?.stockInfoList || []}
                        gStockInfo={goodsDetails?.gStockInfo} />
                </Content>
                <BackButton />
            </Wrapper>
        )}
    </>
    )
}

export default GoodsDetails;
