import { Box, Stack, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { ROLE } from "constants";
import React from "react";
import ClientItem from "../ClientItem";
import GoodsItem from "../GoodsItem";


const ConversationItem = ({ item }) => {
  const isUser = item.role === ROLE.USER;
  return <Stack direction={'row'} sx={{ backgroundColor: isUser ? "#ececec" : "#fff", padding: 1 }}>
    {/* Avarta */}
    <Box>
      <Box sx={{ height: 30, width: 30, borderRadius: "100%", backgroundColor: isUser ? "gray" : "aqua" }}></Box>
    </Box>
    {/* Message */}
    <Box flex={1} sx={{ pl: 1 }}>
      <Typography sx={{ fontWeight: 700 }}>{isUser ? "User" : "Assistant"}</Typography>
      {/* <Box fontSize={"0.9rem"}><pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{item.content || ""}</pre></Box> */}
      <Box fontSize={"0.9rem"}><ReactMarkdown>{item.content || ""}</ReactMarkdown></Box>
      {(!!item.dtClient || !!item.dtGoods) && (
        <Box sx={{ maxHeight: 400, overflowY: "auto", scrollbarWidth: "thin", border: "1px solid #ccc", borderRadius: 1, p: 1, mx: 1, backgroundColor: "#f9f9f9" }}>
          {!!item.dtGoods && item.dtGoods.length > 0 && (<>
            {item.dtGoods.map((g, index) => (
              <GoodsItem key={index} goods={g} />
            ))}
          </>)}

          {!!item.dtClient && item.dtClient.length > 0 && (<>
            {item.dtClient.map((c, index) => (
              <ClientItem key={index} client={c} />
            ))}
          </>)}
        </Box>
      )}

    </Box>
  </Stack>
}

export default ConversationItem;