import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { StockInfo, GoodsInfo, SalesGoods, SHeader, Wrapper, GoodsStock } from './styles';
import SalesGoodsItem from '../SalesGoodsItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import TwitterGoodsItem from '../TwitterGoodsItem';
import StockInfoItem from '../StockInfoItem';


const SalesSlipsOfGoods = ({ salesGoods, twitterGoods, stockInfoList, gStockInfo }) => {
    const [showSalesGoods, setShowSalesGoods] = useState(true);
    const [showGoodsInfo, setShowGoodsInfo] = useState(true);
    const [showStockInfo, setShowStockInfo] = useState(true);
    const [showGoodsStock, setShowGoodsStock] = useState(true);


    const handleShowSalesGoods = (e) => {
        setShowSalesGoods(e.target.checked);
    }

    const handleGoodsInfo = (e) => {
        setShowGoodsInfo(e.target.checked);
    }

    const handleShowStockInfo = (e) => {
        setShowStockInfo(e.target.checked);
    }

    const handleShowGoodsStock = (e) => {
        setShowGoodsStock(e.target.checked);
    }

    return (
        <Wrapper>
            <SHeader>
                <label>標準在庫</label>
                <input hidden id="id_showGoodsStock" type="checkbox" onChange={handleShowGoodsStock} checked={showGoodsStock} />
                {showGoodsStock ? <label htmlFor="id_showGoodsStock" className="btn"><FontAwesomeIcon icon={faAnglesDown} /></label> :
                    <label htmlFor="id_showGoodsStock" className="btn"><FontAwesomeIcon icon={faAnglesUp} /></label>
                }
            </SHeader>
            {(!!gStockInfo?.length && !showGoodsStock) && (<GoodsStock>
                <table>
                    <thead>
                        <tr>
                            <th>場所</th>
                            <th>最低数</th>
                            <th>発注数</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gStockInfo.map(gs => (
                            <tr key={gs.shelf}>
                                <td className='align-left'>{gs.Shelf}</td>
                                <td>{gs.NumberMinimum}</td>
                                <td>{gs.NumberToOrder}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </GoodsStock>
            )}
            <SHeader>
                <label>伝票</label>
                <input hidden id="id_showSalesGoods" type="checkbox" onChange={handleShowSalesGoods} checked={showSalesGoods} />
                {showSalesGoods ? <label htmlFor="id_showSalesGoods" className="btn"><FontAwesomeIcon icon={faAnglesDown} /></label> :
                    <label htmlFor="id_showSalesGoods" className="btn"><FontAwesomeIcon icon={faAnglesUp} /></label>
                }
            </SHeader>
            <SalesGoods hidden={showSalesGoods}>
                {salesGoods.length > 0 ? salesGoods.map(item => (
                    <SalesGoodsItem key={`sgd_${item.SalesGoodsID}`} salesGoodsItem={item} />
                )) : <label>データなし</label>}
            </SalesGoods>
            <SHeader className="border-top-3">
                <label>商品情報</label>
                <input hidden id="id_showGoodsInfo" type="checkbox" onChange={handleGoodsInfo} checked={showGoodsInfo} />
                {showGoodsInfo ? <label htmlFor="id_showGoodsInfo" className="btn"><FontAwesomeIcon icon={faAnglesDown} /></label> :
                    <label htmlFor="id_showGoodsInfo" className="btn"><FontAwesomeIcon icon={faAnglesUp} /></label>
                }
            </SHeader>
            <GoodsInfo hidden={showGoodsInfo}>
                {twitterGoods.length > 0 ? twitterGoods.map(item => (
                    <TwitterGoodsItem key={`gi_${item.TwitterID}`} twitterGoodsItem={item} />
                )) : <label>データなし</label>}
            </GoodsInfo>
            <SHeader>
                <label>在庫状況</label>
                <input hidden id="id_showStockInfo" type="checkbox" onChange={handleShowStockInfo} checked={showStockInfo} />
                {showStockInfo ? <label htmlFor="id_showStockInfo" className="btn"><FontAwesomeIcon icon={faAnglesDown} /></label> :
                    <label htmlFor="id_showStockInfo" className="btn"><FontAwesomeIcon icon={faAnglesUp} /></label>
                }
            </SHeader>
            <StockInfo hidden={showStockInfo}>
                {stockInfoList.length > 0 ? stockInfoList.map(item => (
                    <StockInfoItem key={`sti_${item.StockID}`} stockInfoItem={item} />
                )) : <label>データなし</label>}
            </StockInfo>
        </Wrapper >
    )
}


SalesSlipsOfGoods.propTypes = {
    salesGoods: PropTypes.array,
    twitterGoods: PropTypes.array,
}

SalesSlipsOfGoods.defaultProps = {
    salesGoods: [],
    twitterGoods: []
}

export default SalesSlipsOfGoods
