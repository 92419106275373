import { Box, Typography } from "@mui/material";

const GoodsItem = ({ goods }) => {
  return (
    <Box sx={{ border: "1px solid #ccc", padding: 1, borderRadius: 1, marginTop: 1 }}>
      <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold", color: "#009527" }} >{goods.GoodsName}</Typography>
      <Typography sx={{ fontSize: "0.8rem" }}>{goods.GoodsSize}</Typography>
    </Box>
  );
}
export default GoodsItem;